<template>
  <component :is="tag" v-on="$listeners">
    <b-avatar
      v-if="user.avatar == null || user.avatar == ''"
      size="42"
      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
      class="badge-minimal"
      variant="transparent"
    />
    <b-avatar
      v-else
      size="42"
      :src="user.avatar"
      class="badge-minimal"
      variant="transparent"
    />
    <!-- :badge="isChatContact" -->
    <!-- :badge-variant="resolveAvatarBadgeVariant(user.status)" -->
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ user.name }}
      </h5>
      <p class="card-text text-truncate">
        {{ user.details }}
      </p>
      <!-- {{ isChatContact ? user.message : user.message }} -->
    </div>
    <div v-if="isChatContact" class="chat-meta text-nowrap">
      <small class="float-right mb-25 chat-time">{{
        formatDateToMonthShort(user.date, {
          hour: "numeric",
          minute: "numeric",
        })
      }}</small>
      <!-- <b-badge
        v-if="user.chat.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.chat.unseenMsgs }}
      </b-badge> -->
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import useChat from "./useChat";

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
};
</script>

<style></style>
